
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #f8f9fa, #e3f1f5);
  min-height: 100vh;
  font-size: 14px;
}

.App {
  margin: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #f8f9fa, #e3f1f5);
}

.panel {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-container {
  text-align: center;
  width: 300px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.input-container {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}




.dashboard th,
.dashboard td {
  padding: 0.5rem;
  border: 1px solid #ccc;
  text-align: left;
}

.dashboard th {
  font-weight: bold;
  background-color: #f2f2f2;
}

/* ... (existing code) */

/* ... (existing code) */

.dashboard {
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  overflow-y: auto;
  flex-grow: 1;
}

/* Responsive table */
.dashboard table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  white-space: nowrap;
  word-wrap: break-word;
}

.cell-wrap-text {
  white-space: normal !important;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.report-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

/* Use media queries to adjust table font size for smaller screens */
@media (max-width: 600px) {
  .dashboard table {
    font-size: 0.8em;
  }
}
.eventSelectorContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.eventSelectorLabel {
  margin-right: 10px;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
}

.eventSelector {
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.2s;
}

.eventSelector:hover {
  border-color: #0056b3;
}

.eventSelector:focus {
  outline: none;
  border-color: #0056b3;
}

.eventSelector option:nth-of-type(odd) {
  background-color: #c8f0fc;
}

.eventSelector option:nth-of-type(even) {
  background-color: #f0f0f0;
}

.customFooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

.customFooterItem {
  font-weight: bold;
}


.cell-wrap-text {
  white-space: normal !important;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.grid-buttons {
  display: flex !important;
  justify-content: space-between !important;
  padding: 1rem 0 !important;
}

.grid-button {
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  padding: 0.5rem 1rem !important;
  border-radius: 5px !important;
  margin: 0 5px !important;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.grid-button:hover {
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.grid-button:active {
  transform: translateY(0) !important;
}

/* Individual button colors */
.grid-button:nth-of-type(1) {
  background-color: #007bff;
}
.grid-button:nth-of-type(1):hover {
  background-color: #0056b3;
}
.grid-button:nth-of-type(1):active {
  background-color: #004085;
}

.grid-button:nth-of-type(2) {
  background-color: #28a745;
}
.grid-button:nth-of-type(2):hover {
  background-color: #218838;
}
.grid-button:nth-of-type(2):active {
  background-color: #1e7e34;
}

.grid-button:nth-of-type(3) {
  background-color: #ffc107;
}
.grid-button:nth-of-type(3):hover {
  background-color: #e0a800;
}
.grid-button:nth-of-type(3):active {
  background-color: #c69500;
}

.grid-button:nth-of-type(4) {
  background-color: #dc3545;
}
.grid-button:nth-of-type(4):hover {
  background-color: #bd2130;
}
.grid-button:nth-of-type(4):active {
  background-color: #9a1f28;
}

.grid-button:nth-of-type(5) {
  background-color: #17a2b8;
}
.grid-button:nth-of-type(5):hover {
  background-color: #138496;
}
.grid-button:nth-of-type(5):active {
  background-color: #0f6674;
}

.filter-container {
  position: relative;
  margin-bottom: 1rem;
}

.filter-label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
  color: #007bff;
  font-weight: bold;
}

.filter-input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #007bff;
  border-radius: 5px;
  font-size: 0.9rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.filter-input:focus {
  border-color: #0056b3;
  box-shadow: 0 0 0 2px rgba(0, 91, 187, 0.25);
  outline: none;
}

.mui-filter-label {
  color: #007bff !important;
  font-weight: bold !important;
}

.mui-filter-input {
  border-color: #007bff !important;
}

.mui-filter-input:focus {
  border-color: #0056b3 !important;
  box-shadow: 0 0 0 2px rgba(0, 91, 187, 0.25) !important;
}

.currency {
  color: #ffc107;
}
.currency-portion {
  color: #dc3545;
}

.total-ticket{
  color: #28a745;
}

.scanned{
  color: #17a2b8;
}

.container {
    max-width: '1500px';
    margin: 0 auto;
    padding: 2rem;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .dateRangeContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .dateInput {
    margin: 0 0.5rem;
  }
  
  .chartContainer {
    height: 500px;
    width: 100%;
    background-color: white;
  }
  
  .chart {
    height: 100%;
    width: 100%;
  }

  .chartTypeSelectorContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .chartType {
    margin-left: 0.5rem;
  }

  .chartTypeLabel {
    margin-left: 20px;
  }
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(45deg, #f8f9fa, #e3f1f5);
    min-height: 100vh;
    font-size: 14px;
  }

  .App {
    margin: 30px;
    margin-right: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(45deg, #f8f9fa, #e3f1f5);
    /* transform: scale(0.9); */
  }

.panel {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .tooltip {
    position: absolute;
    display: none;
    background-color: white;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 1000;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
  
  .content-container {
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .content-container > div {
    width: fit-content;
    margin: 0 auto;
  }
  
  .txclSmall2 {
    font-size: 10px;
    font-weight: bold;
    color: #000000;
    background-color: #FFFFFF;
    text-align: center;
    height: 23px;
    width: 20px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    font-family: Verdana, Geneva, sans-serif;
    padding: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    border-top-width: 0px;
    border-right-width: 0px;
  }
  
  .txclSmall4 {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 6px;
    text-align: center;
    width: 23px;
    height: 20px;
    font-style: normal;
    padding: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    color: #FFFFFF;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    font-weight: normal;
  }

  .txclSmall8 {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 10px;
    text-align: center;
    width: 23px;
    height: 20px;
    font-style: normal;
    padding: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    color: #FFFFFF;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    font-weight: normal;
  }
  
  .txclSmall10 {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 5px;
    text-align: center;
    width: 20px;
    height: 18px;
    font-style: normal;
    padding: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    color: #FFFFFF;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    font-weight: bold;
  }

  .txclSmall12 {
    font-size: 7px;
    font-weight: bold;
    color: #000000;
    background-color: #FFFFFF;
    text-align: center;
    width: 20px;
    height: 18px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    font-family: Verdana, Geneva, sans-serif;
    padding: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    border-top-width: 0px;
    border-right-width: 0px;
  }

  .txcl {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 10px;
    text-align: center;
    width: 33px;
    height: 30px;
    font-style: normal;
    padding: 0px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    color: #FFFFFF;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    font-weight: normal;
  }
 
  .txcl2 {
    font-size: 10px;
    font-weight: bold;
    color: #000000;
    background-color: #FFFFFF;
    text-align: center;
    height: 33px;
    width: 30px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    font-family: Verdana, Geneva, sans-serif;
    padding: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #FFFFFF;
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
    border-left-color: #FFFFFF;
    border-top-width: 0px;
    border-right-width: 0px;
  }
  .input-buttons-container {
    display: flex;
    justify-content: center;
  }
  
  .selective-radio {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 12px;
    text-align: center;
    width: 32px;
    height: 32px;
    font-style: normal;
    padding: 0;
    border: 1px solid #ccc;
    color: #333;
    background-color: #fff;
    font-weight: normal;
    margin-right: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .selective-radio:hover {
    background-color: #f5f5f5;
  }
  
  .selected-input-button {
    background-color: yellow;
  }

  .radio-buttons-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
  
  .radio-buttons-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .radio-buttons-container div {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .radio-buttons-container input[type="radio"] {
    margin-right: 5px;
  }
  
  .radio-buttons-container label {
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
  }

  .price-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .price-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 120px;
  }
  
  .price-card h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .price-card p {
    margin: 0;
  }
  
  .selected-price-card {
    background-color: #cce6ff;
    border: 1px solid #007aff;
  }

  .price-card:hover {
    cursor: pointer;
    border: 1px solid #007aff;
  }

  .selected-price-text {
    /* Add your highlighting style here */
    background-color: #f0ad4e;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    padding: 2px 4px;
  }

  .total-count-text:hover {
    cursor: pointer;
    color: #ff0055;
  }

  .eventSelectorContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .eventSelectorLabel {
    margin-right: 10px;
    font-size: 1.1rem;
    color: #333;
    font-weight: bold;
  }
  
  .eventSelector {
    padding: 0.5rem;
    font-size: 1rem;
    border: 2px solid #007bff;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.2s;
  }
  
  .eventSelector:hover {
    border-color: #0056b3;
  }
  
  .eventSelector:focus {
    outline: none;
    border-color: #0056b3;
  }
  
  .eventSelector option:nth-of-type(odd) {
    background-color: #c8f0fc;
  }
  
  .eventSelector option:nth-of-type(even) {
    background-color: #f0f0f0;
  }
  
  .customFooter {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .cell-wrap-text {
    white-space: normal !important;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .refresh-button {
    cursor: pointer; /* Change the cursor to a pointer when hovering */
    transition: background-color 0.3s; /* Add smooth transition for background color */
    background-color: #ccc; 
    margin-top: 10px;
    
  }
  
  .refresh-button:hover {
    /* Change the background color when hovering */
    background-color: #f5730a;
  }

  /* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 8px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.activeEventsContainer {
  display: block;
  margin-bottom: 1em;
}

.selectEventContainer {
  display: block;
}

.showActiveEventsLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-top: 1rem;
  cursor: pointer;
}

.invalid-price-card {
  background-color: #f3bbbb;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinking {
  background-color: #f3bbbb;
  animation: blink 1s linear infinite;
}
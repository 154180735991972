.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    position: absolute;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    user-select: none;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-button {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: white;
    border: 2px solid #999;
    border-radius: 50%;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    color: #999;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    transition: color 0.2s, border-color 0.2s;
  }
  
  .close-button:hover {
    color: #333;
    border-color: #333;
  }
  

  .modal-button {
    background-color: #3f51b5;
    color: white;
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
    margin: 4px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
  
  .modal-button:hover {
    background-color: #333;
    border-color: #333;
    color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }

  /* Add a new class for modal buttons */
  .modal-button-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .modal-price-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .modal-price-input-container input {
    width: 70%;
    padding: 4px 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 14px;
  }
  .modal-price-input-container input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .draggable-area {
    display: inline-flex;
    cursor: move;
  }


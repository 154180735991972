/* Logout.css */
header {
    position: fixed;
  }
  
  .logout {
    position: fixed;
    top: 0;
    right: 0;
    padding: 10px;
    z-index: 1000;
  }

  .logout-button {
    background-color: #f44336;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 5px;
  }

  .back-button {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 1000;
  }
  
.user-list {
    flex: 1;
    margin-right: 1rem;
  }
  
  .permission-list {
    flex: 1;
    margin-left: 1rem;
  }

  .radio-buttons-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f7f7f7;
}

.userList {
  max-height: 600px;
  /* overflow-y: auto; */
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(to bottom, #f9f9f9 0%, #e9e9e9 100%);
  padding: 1rem;
}
  
.userItem {
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.5rem 1rem;
}
  
  .userItem:hover {
    background-color: #e0e0e0;
  }
  
  .userItem.selected {
    background-color: #bcaafd;
  }
  
  .permissionList, .eventList {
    max-height: auto;
    overflow-y: auto;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, #f9f9f9 0%, #e9e9e9 100%);
    padding: 1rem;
  }
  
  .formControl {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    border-radius: 4px;
    padding: 4px;
  }
  
  .formControl:hover {
    background-color: #cfcdcd;
    /* border-radius: 4px;
    padding: 4px; */
  }
  
  .createUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .createUser h2 {
    margin-bottom: 20px;
  }
  
  .createUser .textfield {
    margin-bottom: 10px;
  }
  
  .eventList {
    max-height: 1000px;
    overflow-y: auto;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: linear-gradient(to bottom, #f9f9f9 0%, #e9e9e9 100%);
    padding: 10px;
  }

  .deleteUser {
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.54); /* Change this to the initial color you want for the delete icon */
  }
  
  .deleteUser:hover {
    color: red; /* Change this to the highlight color you want when hovering */
    cursor: pointer;
  }
body {
  margin: 0;
  margin-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #007bff, #33ceff);
  min-height: 100vh;
}

.App {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #007bff, #33ceff);
  font-size: 14px;
  /* transform: scale(0.9); */
}

.login-container {
  text-align: center;
  width: 300px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.input-container {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

button {
  display: block;
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}




.dashboard th,
.dashboard td {
  padding: 0.5rem;
  border: 1px solid #ccc;
  text-align: left;
}

.dashboard th {
  font-weight: bold;
  background-color: #f2f2f2;
}

/* ... (existing code) */

/* ... (existing code) */

.dashboard {
  padding: 1rem;
  box-sizing: border-box;
  max-width: 100%;
  height: 100%; /* Subtract the padding */
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  overflow-y: auto;
  flex-grow: 1;
}

/* Responsive table */
.dashboard table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  white-space: nowrap;
  word-wrap: break-word;
}

.cell-wrap-text {
  white-space: normal !important;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.report-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.scrollable-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 600px) {
  .scrollable-container {
    overflow-x: scroll;
  }
}


/* Use media queries to adjust table font size for smaller screens */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, #f8f9fa, #e3f1f5);
  min-height: 100vh;
  font-size: 14px;
}

.App {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #f8f9fa, #e3f1f5);
}

.panel {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-container {
  text-align: center;
  width: 300px;
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.input-container {
  margin-bottom: 1rem;
}

label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

input {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.radio-buttons-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.radio-buttons-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.eventSelectorContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.eventSelectorLabel {
  margin-right: 10px;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
}

.eventSelector {
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.2s;
}

.eventSelector:hover {
  border-color: #0056b3;
}

.eventSelector:focus {
  outline: none;
  border-color: #0056b3;
}

.eventSelector option:nth-of-type(odd) {
  background-color: #c8f0fc;
}

.eventSelector option:nth-of-type(even) {
  background-color: #f0f0f0;
}

.complementaryContainer {
  display: block;
  margin-bottom: 1em;
}

.complementaryLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-top: 1rem;
  cursor: pointer;
}

.codContainer {
  display: block;
  margin-bottom: 1em;
}

.codLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-top: 1rem;
  cursor: pointer;
}

.new-order-button {
  background-color: #007bff;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin-bottom: 20px;
}

.new-order-button:hover {
  background-color: #0056b3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.new-order-button:focus {
  outline: none;
}
.new-order-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.message {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.message.success {
  color: #4caf50;
}

.message.error {
  color: #f44336;
}
.dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.dashboard-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}